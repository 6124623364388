@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

a:hover {
    text-decoration: none;
}

main {
    position: relative;
    background-color: #f8f9fa;
}

main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    pointer-events: none;
    background-image: linear-gradient(#00458088 1px, transparent 1px), 
                     linear-gradient(to right, #00458088 1px, #f8f9fa 1px);
    background-size: 32px 32px;
    z-index: 0;
}

/* Ensure main content stays above the background */
main > * {
    position: relative;
    z-index: 1;
}

.btn {
    background: transparent;
    border-color: #ff8000;
}
.btn:hover {
    background: #ff8000;
}

h1 {
    /* background: linear-gradient(30deg,rgb(255, 255, 255,1),rgba(255, 255, 255, 0.6)); */
    color: #004580;
    display: inline-block;
    font-size: 2.2rem;
    font-weight: bold;
    /* text-align: center !important; */
    padding: 10px 20px 10px 0px;
    margin-top: 32px;
    /* border-left: #ff8000 4px solid; */
    /* border-bottom: #004580 solid; */
    text-shadow: #f8f9fa 0 1px 10px;
}
h1::after {
    content: '';
    display: block;
    background: #ff8000;
    height: 4px;
    width: 120px;
    margin-top: 10px;
    /* text-align: left; */
}

h2 {
    /* background: linear-gradient(#f8f9fa 10%,#f8f9fa00); */
    background: radial-gradient(rgba(248,249,255,0.9) 20%,#ffffff00);
    font-size: 2.0rem;
    color: #004580;
    padding-top: 20px;
    /* background: -webkit-linear-gradient(0deg,#004580, #0080bb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}
h2::after {
    content: '';
    display: block;
    background: #ff8000;
    height: 4px;
    width: 120px;
    margin: 10px auto;
    text-align: center;
}

section {
    color: #004580;
    padding: 80px 0;
}
section h2 {
    padding-top: 0;
}
.home-section {
    padding: 140px 0 150px;
    position: relative;
    /* overflow: hidden;
    background: #004580; */
}

/* Add the curved shape at the bottom */
/* .home-section::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 100vh;
    height: 100%;
    background-image: linear-gradient(#00458088 0.25px, transparent 0.25px), 
                     linear-gradient(to right, #00458088 0.25px, #f8f9fa 0.25px);
    background-size: 8px 8px;
    border-radius: 50%;
    transform-origin: bottom;
    transform: translateX(-50%) scale(4);
} */

/* Ensure content stays above the curve */
/* .hero-content {
    position: relative;
    z-index: 1;
} */

.home-section .container {
    background: radial-gradient(rgba(248,249,255,0.9) 40%,#ffffff00);

}
.hero-tagline {
    display: inline-block;
    background: #f8f9fa55;
    color: #0080ff;
    font-size: 0.8rem;
    padding: 0.375rem 0.75rem;
    margin-top: 0px;
    border: #0080ff66 1px solid;
    border-radius: 20px;
    box-shadow: 0 0 10px #dddddd;
}
.hero-tagline span {
    color: #ff8000;
}
.home-section h3 {
    /* background: radial-gradient(rgba(248,249,255,0.6) 40%,#ffffff00); */
    color: #004580;
    font-family: "Poppins", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3.2rem;
}
.home-section h3 span {
    color: #0080ff;
    font-weight: 700;
}

.btn-hero {
    background: #ff8000;
    color: #ffffff;
    border-color: #ff8000;
    padding: 0.6rem 1rem;
    font-weight: bold;
    box-shadow: 0 6px 10px #dddddd;
    border-radius: 20px;
}
.btn-hero:hover {
    background: #ff8000;
    color: #ffffff;
    box-shadow: 0 0 0px #dddddd;
}

.alt-home {
    background: #004580;
    /* background: linear-gradient(150deg,#004580 ,#0080ff); */
    color: #ffffff;
    /* border-radius: 20px; */
    /* box-shadow: 0 0 10px #dddddd; */
}
.alt-home h2 {
    background: none;
    color: #ffffff;
    /* font-size: 2.6rem; */
}
.alt-home img {
    background: #ffffff;
    width: 100%;
    padding: 90px 40px;
    border-radius: 20px 60px;
}

.testimonials {
    background: #ffffff;
}

section.page {
    background: linear-gradient(#f8f9fa 10%,#f8f9fa00);
}

section.page p::before {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;
    border-radius: 20px;
    opacity: .95;
    z-index: -1;
    background: radial-gradient(rgba(255,255,255,1) 40%,#ffffff00);
}

.product-page svg {
    display: inline-block;
    /* background: #004580; */
    height: 260px;
    width: 100%;
    padding: 60px;
    border-radius: 10px;
    background-color: #004580;
    opacity: 1;
    background-image: radial-gradient(circle at center center, #dddddd, #004580), repeating-radial-gradient(circle at center center, #dddddd, #dddddd, 10px, transparent 20px, transparent 10px);
    background-blend-mode: multiply;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 10px #dddddd;
    transition: 0.25s;
    color: #ffffff;
}

@media (max-width: 768px) {
    .home-section h3 {
        font-size: 1.8rem;
    }
    .home-section p {
        font-size: 1.0rem;
    }
}