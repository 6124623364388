.about-page h2, .about-page h3 {
  color: #007bff;
}

.about-page p {
  line-height: 1.6;
}

.about-page ul li {
  font-size: 1.1rem;
}

.card {
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  color: #ff8000;
}

