.footer {
  /* background: #004580; */
  background: linear-gradient(60deg,#004580,#495057);
  color: #f3f3f3;
  margin-top: auto;
}

.footer h5 {
  color: #ffffff;
  margin-bottom: 1rem;
}

footer h5::after {
  content: '';
  display: block;
  background: #0080ff;
  height: 4px;
  width: 60px;
  margin-top: 10px;
  /* text-align: center; */
}

.footer .logo {
  /* height: 60px; */
  width: 65%;
  filter: contrast(0%) brightness(0%) invert(100%);
  /* filter: grayscale(100%) invert(200%); */
  padding: 10px 0;
}

.footer ul li {
  margin-bottom: 0.5rem;
}

.footer a {
  color: #ffffff;
  padding: 6px 8px;
  text-decoration: none;
  border-radius: 4px;
  transition: 0.3s;
}
.footer .links a {
  display: block;
}
.footer a:hover {
  background: linear-gradient(90deg,#ff8000,#ff800000);
}

.social-icons a {
  font-size: 18px;
  display: inline-block;
  color: #0080ff;
  background: #f3f3f3;
  line-height: 1;
  padding: 7px 0;
  margin-right: 4px;
  border-radius: 6px;
  border: 1px solid #f3f3f3;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.social-icons a:hover {
  background: #ffffff;
  color: #ff8000;
  border-radius: 10px;
  border: 1px solid #ff8000;
}

.footer hr {
  border-color: #747474;
}

.dev {
  color: #f8f9fa;
  font-size: 12px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}
.dev a:hover {
  background: none;
  text-decoration-line: underline;
  /* background: #ff8000; */
}