@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

nav {
  position: fixed;
  background: linear-gradient(#ffffff 60%,#ffffff00);
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 0px solid #dddddd00;
  z-index: 1000;
  transition: all 0.3s ease;
}
/* .homebody header {
  background: #004580;
} */

nav.scrolled {
  background: linear-gradient(#ffffff 60%,#ffffff);
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  border-bottom: 1px solid #dddddd;
}

/* Smooth background transition */
nav::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

header.scrolled::before {
  opacity: 1;
}

nav{
  /* position: fixed;
  z-index: 99;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #dddddd; */
  /* box-shadow: 0 2px 6px rgba(0,0,0,0.15); */
}
nav .wrapper{
  position: relative;
  height: 75px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo a{
  color: #004580;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.wrapper .logo a img {
  height: 36px;
  margin-top: 6px;
}
label {
  margin-bottom: 0 !important;
}
.wrapper .nav-links{
  display: inline-flex;
}

/* Keep your existing styles and add: */
.nav-links {
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .nav-links {
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100vh;
      background: white;
      padding-top: 60px;
  }

  .nav-links.active {
      left: 0;
  }
}

.nav-links li{
  list-style: none;
}
.nav-links li a{
  color: #004580;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links li a:hover, .nav-links li a.active{
  color: #ff8000;
}
.nav-links .mobile-item{
  display: none;
}
.nav-links .drop-menu{
  position: absolute;
  background: #f2f2f2;
  width: 200px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.drop-menu li a:hover, .drop-menu li a.active {
  background: #ff8000;
  color: #ffffff;
}
.mega-box{
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content{
  background: #f2f2f2;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.mega-box .content .row{
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header{
  color: #004580;
  font-size: 20px;
  font-weight: 500;
}
.content .row .mega-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
}
.row .mega-links li{
  padding: 0 20px;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  color: #0084ff;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover{
    background: #0084ff;
    color: #f2f2f2;
}
.wrapper .btn{
    /* background: #0084ff; */
    color: #0084ff;
    font-size: 12px;
    cursor: pointer;
    display: none;
    padding: 8px 12px 8px;
    border: #0084ff solid 1px;
}
.wrapper .btn.close-btn{
    position: absolute;
    right: 20px;
    top: 20px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn{
    display: block;
  }
  .wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #f2f2f2;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }
  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn{
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn{
    display: block;
  }
  .nav-links li{
    margin: 15px 10px;
  }
  .nav-links li a{
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box{
    max-height: 100%;
  }
  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: #004580;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
    background: #004580;
    color: #f2f2f2;
  }
  .drop-menu li{
    margin: 0;
  }
  .drop-menu li a{
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content{
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row{
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(0,0,0,0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
}
nav input{
  display: none;
}

.body-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 600px 30px;
}
.body-text div{
  font-size: 45px;
  font-weight: 600;
}