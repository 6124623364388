.testimonial-section {
    /* background: #ff8000; */
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    padding: 0 50px;
}
@media (max-width: 768px) {
    .testimonial-section {
        padding: 14px;
    }
}
.testimonial-section .hero-swiper {
    /* display: flex;
    align-items: center; */
    height: max-content !important;
    /* height: 220px; */
    /* margin: 40px; */
}
.testimonial-section .swiper-wrapper {
    height: max-content;
    align-items: center;
}
.testimonial-section .content {
    /* background: #dddddd; */
    color: #454545;
    min-height: 145px;
    /* padding-bottom: 12px; */
}
.testimonial-section p {
    font-size: 0.9rem;
}
.testimonial-section p span {
    font-weight: bold;
    font-size: 1.0rem;
}
.testimonial-section .test-cred {
    bottom: 0;
}
.testimonial-section .row svg {
    height: 100%;
    font-size: 4.2rem;
    color: #ff8000;
}
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 20px;
}