.hero-section {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #dddddd;
}

.hero-section .hero-swiper {
  height: 320px;
}
.swiper-slide {
  /* background: linear-gradient(120deg,#f8f9fa,#dddddd); */
}
.swiper-slide .bg-pattern {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  gap: 1rem;
  padding: 1rem;
  opacity: 0.2;
}
.hero-background {
  background: linear-gradient(#0080ff,#004580);
  /* background-color: rgba(255, 128, 0, 0.9); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: auto 32%;
  background-repeat: no-repeat;
  background-position: left 100%;
  /* filter: grayscale(80%); */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.product-info {
  background-color: rgba(255, 255, 255, 0.9);
  color: #0080ff;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 20%;
  width: max-content;
  text-align: right;
  padding: 16px 16px 10px 32px;
  border-radius: 10px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
  transition: all 0.3s;
}
.product-info h3 {
  font-size: 2.0rem;
  margin: 16px 0 0px;
}
.product-info p {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.product-info:hover {
  background: rgba(255, 128, 0,0.95);
  /* color: #ffffff; */
  box-shadow: 0 0px 16px rgba(0, 0, 0, 0.1);
}
.product-info.product-list:hover svg {
  color: #ffffff;
}

.swiper-button-prev,
.swiper-button-next {
  color: rgba(255,69,0, 0.6) !important;
  /* background-color: rgba(255,69,0, 0.6); */
  width: 20px !important;
  height: 40px !important;
  border-radius: 10%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all 0.3ms ease-in;
}

.swiper-button-next {
  right: 0;
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(255,69,0, 1);
  color: #fff !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: none !important;
}

.swiper-pagination {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.3);
}

.swiper-pagination-progressbar-fill {
  background-color: #0080ff !important;
}

.preview-container {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 2;
}

.preview-item {
  width: 60px;
  height: 40px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.preview-item:hover {
  transform: scale(1.1);
  border: 1px solid #0080ff;
}

.preview-item.active {
  border: 2px solid #fff;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .swiper-slide-active .product-info {
  animation: fadeIn 0.5s ease-in-out;
} */

@media (max-width: 768px) {
  .swiper-slide .bg-pattern {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20px, 1fr));
    gap: 1rem;
    padding: 1rem;
    opacity: 0.2
  }
  .product-info {
    right: 10%;
    /* left: 5%; */
    max-width: 90%;
  }

  /* .preview-container {
    bottom: 60px;
  } */
}

