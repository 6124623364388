.product-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
    row-gap: 10px;
}
.product-list a {
    display: inline-block;
    background: linear-gradient(150deg,rgba(255, 255, 255, 0.6),rgba(248,249,250,1),rgba(255, 255, 255, 0.6));
    height: max-content;
    width: 100%;
    padding: 32px;
    border: 1px solid #dddddd;
    border-radius: 10px;
}
.product-list a:hover {
    background: #ff8000;
    background-color: #004580;
    opacity: 1;
    background-image: radial-gradient(circle at center center, #dddddd, #004580), repeating-radial-gradient(circle at center center, #dddddd, #dddddd, 10px, transparent 20px, transparent 10px);
    background-blend-mode: multiply;
    border: 1px solid #004580;
    box-shadow: 0px 0px 10px #454545;
    transition: 0.25s;
}
.product-list .thumb-cont {
    position: relative;
}

.product-list .thumbnail {
    height: 120px;
    border-radius: 10px;
    background: #ffffff;
    background-position: center;
    background-size: auto 60%;
    background-repeat: no-repeat;
    filter: grayscale(60%);
}
.product-list a:hover .thumbnail {
    border-radius: 10px 10px 4px 4px;
    /* background-size: auto 85%; */
    filter: grayscale(0%);
    transition: 0.25s;
}
.product-list svg {
    font-size: 2.6rem;
    color: #ff8000;
    transition: all 0.3s;
}
/* .product-list a:hover svg {
    box-shadow: 0 0 10px #ffffff;
} */
.product-list .title {
    font-size: 1.4rem;
    /* font-weight: bold; */
    color: #004580;
    padding: 10px 0px 0;
    margin: 0;
}
.product-list a:hover .title {
    color: #ffffff;
}
.product-list .author {
    color: #454545;
    font-size: 0.9rem;
    padding: 0 6px 10px;
}
.product-list .category {
    background: #ff533d;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 4px 6px;
    width: fit-content;
    /* border-radius: 4px; */
    /* border-left: 2px solid #002BFF; */
}
.product-list .category:hover {
    background: #061431;
    color: #ffffff !important;
}
.product-list .thumb-cat {
    position: absolute;
    margin: 8px;
}

@media (max-width: 992px) {
    .product-list ul {
        grid-template-columns: auto auto auto;
    }
}

@media (width: 912px), (width: 820px), (width: 768px) {
    .product-list ul {
        grid-template-columns: auto auto;
    }
}

@media (max-width: 768px) {
    .product-list ul {
        grid-template-columns: auto;
    }
    /* .product-list ul li {
        width: 60%;
    } */
}