.contact-info {
  background-color: #ffffff;
  height: 402px;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-info h3 {
  color: #333;
  margin-bottom: 1rem;
}

.contact-info ul li {
  margin-bottom: 0.5rem;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #0056b3;
}

.map-container {
  background-color: #ffffff;
  height: 402px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

