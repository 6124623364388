#article-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
    row-gap: 10px;
    /* -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    column-gap: 10px; */
}
#article-section ul li {
    display: inline-block;
    background: #ffffff;
    color: #ff533d;
    height: max-content;
    width: 100%;
    padding: 10px;
    /* border: #000000 4px solid; */
}
#article-section ul li:hover {
    box-shadow: 0px 0px 20px #dddddd;
    /* border-radius: 10px; */
    transition: 0.25s;
}
#article-section .thumb-cont {
    position: relative;
}
#article-section .thumbnail {
    height: 180px;
    /* border-radius: 10px; */
    background: #ffffff;
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
}
#article-section a:hover .thumbnail {
    /* border-radius: 10px 10px 0 0; */
    background-size: auto 105%;
    transition: 0.25s;
}
#article-section .title {
    color: #061431;
    font-weight: bold;
    padding: 10px 6px 0;
}
#article-section a:hover .title {
    color: #ff533d;
}
.author {
    color: #454545;
    font-size: 0.9rem;
    padding: 0 6px 10px;
}
.category {
    background: #ff533d;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 4px 6px;
    width: fit-content;
    /* border-radius: 4px; */
    /* border-left: 2px solid #002BFF; */
}
.category:hover {
    background: #061431;
    color: #ffffff !important;
}
.thumb-cat {
    position: absolute;
    margin: 8px;
}
